import { clickSignUpButtonTracker, signUpValidationFailedTracker } from 'util/analytics/gtm';

export default function getSingupValidationTrackers() {
  let signupValidationError = false;
  let hasSentSignupError = false;

  const form = document.querySelector('#new_user');
  if (form !== null) {
    /**
     * Here we are setting up an observer to check for DOM changes.
     * If the DOM changes and we find the `.new-user-msgbox` element,
     * we know the validation has failed and we can sent a GTM event.
     */
    const observer = new MutationObserver((nodeList) => {
      const errorMessageContainer = nodeList.find((element) => element.target.id === 'new-user-msgbox');
      if (hasSentSignupError) return;
      if (errorMessageContainer) signupValidationError = true;
      if (signupValidationError) {
        hasSentSignupError = true;
        signUpValidationFailedTracker();
      }
    });

    const observerOptions = {
      childList: true,
      subtree: true,
    };

    observer.observe(form, observerOptions);
  }

  const clickHandler = () => {
    clickSignUpButtonTracker({ signupProvider: 'email-and-password' });
  };

  const emailSignupButton = document.querySelector('#signup-button');
  if (emailSignupButton !== null) {
    emailSignupButton.addEventListener('click', clickHandler);
  }
}
